/* // 面包屑导航预配置
const crumbNavList = new Map([
  ["msgDetail", [{ title: "Mensajes", path: "/message" }]],
  ["activityDetail", [{ title: "Listado de Promo", path: "/activityList" }]],
  ["resetPwd", [{ title: "Acceder", path: "/login" }]],
]);

// 静态页的code对应的title
const titleMap = new Map([
  ["agreement_page", "Términos y Condiciones"], // 用户协议
  ["privacy_page", "Políticas de Privacidad"], // 隐私协议
  ["responsable_page", "Juegos Responsables"], // 责任博彩
  ["quejas_page", "Procedimiento de Quejas"], // 投诉
]); */

// pages枚举
export const pageEnum = {
  // casino: "/pages/casino",
  // casinoVivo: "/pages/casinoVivo",
  // activityList: "/pages/activityList",
  // me: "/pages/user",
  '/': '/pages/casino', // 首页
  activityDetail: '/pages/activityDetail',
  set: '/pages/set',
  agreementList: '/pages/agreementList',
  login: '/pages/login',
  register: '/pages/register',
  resetPwd: '/pages/resetPwd',
  changePwd: '/pages/changePwd',
  personal: '/pages/personal',
  verifyCode: '/pages/verifyCode',
  mobileModify: '/pages/mobileModify',
  mobileVerify: '/pages/mobileVerify',
  vip: '/pages/vip',
  customer: '/pages/customer',
  info: '/pages/info',
  recharge: '/pages/recharge',
  withdraw: '/pages/withdraw',
  balance: '/pages/balance',
  handselRecord: '/pages/handselRecord',
  rechargeRecord: '/pages/rechargeRecord',
  gameRecord: '/pages/gameRecord',
  message: '/pages/message',
  msgDetail: '/pages/msgDetail',
  playGame: '/pages/playGame',
  chat: '/pages/chat',
  gameRecharge: '/pages/gameRecharge',
  inviteFriends: '/pages/activitys/inviteFriends',
  rechargeGiveaway: '/pages/activitys/rechargeGiveaway',
  registerGiveaway: '/pages/activitys/registerGiveaway',
  registerRoulette: '/pages/activitys/registerRoulette',
  bingo: '/pages/bingo',
  rechargeRoulette: '/pages/activitys/rechargeRoulette',
  moneyComp: '/pages/activitys/moneyComp', // 补偿损失
  moneyFullRefund: '/pages/activitys/moneyFullRefund', // 包赔活动
  discountCode: '/pages/activitys/discountCode', // 彩金折扣码活动
  taskBox: '/pages/activitys/taskBox', // 任务宝箱
  appInfo: '/pages/appInfo',
  pay: '/pages/pay',
  home: '/pages/home',
  main: '/pages/main',
  h5fb: '/pages/h5fb',
  notice: '/pages/notice',
  search: '/pages/search',
  noAccess: '/pages/noAccess',
  // inviteAgent: '/pages/inviteAgent',

  // miniApp
  inviteFriendsMiniApp: '/pages/miniApp/inviteFriends',
  taskBoxMiniApp: '/pages/miniApp/taskBox', // 小程序任务宝箱
  bourse: '/pages/miniApp/bourse',
  winCoin: '/pages/miniApp/winCoin',
  airDrop: '/pages/miniApp/airDrop',
  rechargeU: '/pages/miniApp/rechargeU',
};

export const tabBarMenu = {
  casino: '/pages/casino',
  sports: '/pages/sports',
  bingo: '/pages/bingo',
  activityList: '/pages/activityList',
  recharge: '/pages/recharge',
  casinoVivo: '/pages/casinoVivo',
  me: '/pages/user',
  withdraw: '/pages/withdraw',
  customer: '/pages/customer', // h5属于
  inviteAgent: '/pages/inviteAgent',
};

// 在util的gotoPage中做登录验证
export const needLoginRouter = {
  recharge: '/pages/recharge',
  withdraw: '/pages/withdraw',
  pay: '/pages/pay',
  playGame: '/pages/playGame',
  vip: '/pages/vip',
  changePwd: '/pages/changePwd',
  activityDetail: '/pages/activityDetail',
  inviteFriends: '/pages/activitys/inviteFriends',
  rechargeGiveaway: '/pages/activitys/rechargeGiveaway',
  rechargeRoulette: '/pages/activitys/rechargeRoulette',
  taskBox: '/pages/activitys/taskBox', // 任务宝箱
  inviteFriendsMiniApp: '/pages/miniApp/inviteFriends',
  taskBoxMiniApp: '/pages/miniApp/taskBox', // 小程序任务宝箱
  inviteAgent: '/pages/inviteAgent',
};
