/** 活动相关接口 */
import request from '@request';

/**
 * 充值送活动充值金额列表
 */
export function QueryRechargeActivity(params) {
  return request.$get('/activity/api/front/queryActivityRecharge', params);
}

/**
 * 注册送活动信息
 */
export function QueryRegisterActivity() {
  return request.$post('/activity/api/front/queryRegisterActivity');
}

// 领取新手大礼包: {amount:12}
export function getRegisterAward(params) {
  return request.$post('/activity/api/newbie/gift/get', params);
}

// 查询待提现
export function QueryOpenWaitWithdrawalAccount(params) {
  return request.$get('/user/api/userinfo/queryOpenWaitWithdrawalAccount', params);
}

// 查询待提现V2
export function QueryOpenWaitWithdrawalAccountV2(params) {
  return request.$get('/user/api/userinfo/queryOpenWaitWithdrawalAccount/v2', params);
}

// 用户待提现配置信息
export function QueryWithdrawalConfig(params) {
  return request.$get('/activity/api/user/wait/withdrawal/config', params);
}

// 用户待提现金额领取  {"ruleId":1}
export function GetWithdrawalAward(params) {
  return request.$post('/activity/api/user/wait/withdrawal/get', params);
}

/**
 * 获取充值转盘活动信息
 */
export function QueryBuletteActivity(params) {
  return request.$get('/activity/api/front/queryRouletteActivity', params);
}

/**
 * 领取转盘中奖信息
 * @param {*} params
 */
export function GetBuletteAward(params) {
  return request.$post('/activity/api/rouletteActivity/join', params);
}

/**
 * 亏损补偿查询
 */
export function lossCompensationActivityQuery(params) {
  return request.$post('/activity/api/lossCompensationActivity/query', params);
}

/**
 * 亏损补偿领取
 */
export function lossCompensationActivityJoin(params) {
  return request.$post('/activity/api/lossCompensationActivity/join', params);
}

/**
 * 包赔活动查询
 */
export function fullCompensationActivityQuery(params) {
  return request.$post('/activity/api/fullCompensationActivity/query', params);
}

/**
 * 包赔活动领取
 */
export function fullCompensationActivityJoin(params) {
  return request.$post('/activity/api/fullCompensationActivity/join', params);
}

/**
 * 彩金折扣码活动领取
 * 参数：{"code":"VIP666"}
 */
export function winningsDiscountCodeJoin(params) {
  return request.$post('/activity/api/winningsDiscountCode/join', params);
}

/**
 * 签到活动
 */
export function signInQuery(params) {
  return request.$post('/activity/api/signIn/query', params);
}

/**
 * 参与签到活动
 */
export function signInJoin(params) {
  return request.$post('/activity/api/signIn/join', params);
}

/**
 * 任务宝箱活动查询
 */
export function taskBoxQuery(params) {
  return request.$post('/activity/api/taskBox/query', params);
}

/**
 * 领取宝箱任务接口
 * POST: /activity/api/taskBox/task/get 领取宝箱任务接口 {"ruleId":1}
 */
export function taskBoxItemGet(params) {
  return request.$post('/activity/api/taskBox/task/get', params);
}

/**
 * 领取宝箱接口
 */
export function taskBoxGet(params) {
  return request.$post('/activity/api/taskBox/box/get', params);
}
/**
 * 获取邀请列表
 */
export function coinsInvite(params) {
  return request.$post('/coins/api/coins/account/invite', params);
}

/**
 * 用户加入官方群组或关注X调用
 */
export function followXOrT(params) {
  return request.$post('/user/api/userinfo/updateUserTag', params);
}
