<script setup>
import '@assets/styles/main.less';
// import '@assets/theme/pink.theme.less';
import '@assets/styles/flex.scss';
import { ref, getCurrentInstance, watch, onUnmounted, inject, nextTick, onMounted, provide } from 'vue';
import { default as config, handleChannel } from '@config';
import { onLaunch, onError, onShow, onHide, getSsrGlobalData } from '@dcloudio/uni-app';
import {
  showLoading,
  hideLoading,
  setLocalData,
  getLocalData,
  setToken,
  getToken,
  getParamsByStr,
  gotoPage,
  checkBrowser,
  checkSite,
  checkPlatform,
  md5,
  getFriendlyCode,
  checkInviteAccountChanged,
} from '@util';
import { COMMONAPI, GAMEAPI, USERAPI, BUYAPI } from '@api';
import { SocketManager } from '@hooks/socketManager';
import { useChat } from '@hooks/chat';
import { useCommonStore } from '@stores/common';
import { storeToRefs } from 'pinia';
import { appLaunchFun } from '@appLaunch';
import routingIntercept from '@/router/index';
import silenceUpdate from '@/uni_modules/rt-uni-update/js_sdk/silence-update.js'; //引入静默更新
import { TonConnectUI } from '@tonconnect/ui';
import toast from './uni_modules/uview-plus/libs/config/props/toast';

const store = useCommonStore();
const {
  setSelectTabbar,
  setTabFirstExpanded,
  setCheckSiteConfig,
  setInviteAccount,
  setInviteAccountChanged,
  setShowCoinsAnim,
} = store;
const { checkSiteConfig, isLogin, categoryData, baseUserInfo, tabFirstExpanded } = storeToRefs(store);

const {
  switchLogin,
  setBaseUserInfo,
  setUserAccount,
  setCategoryData,
  setIsNeedGetUserInfo,
  setAppUpdateType,
  setIsShowHookCoinsDia,
  setHookCoinsData,
  setExchange,
  setWallet,
  setUserCoins,
  setIsShowRecharge1Dia,
  setIsRecharged,
  setNewUserRouletteDia,
} = store;

const { proxy, appContext } = getCurrentInstance();
const global = appContext.config.globalProperties;
global.ChatModule = null;
const $ganaStat = inject('$ganaStat');
const socketManager = new SocketManager();

let Crisp = null;
let handleChannelRes = null;
// TODO:动态设置下拉背景字体、loading 图的样式
// uni.setBackgroundTextStyle();
let platform = checkPlatform();
const tgStartapp = ref('');
let installActive = getLocalData('install_active', null);

console.log('---------platform：', platform);
console.log('---------config：', config);
console.log('---------ua：', navigator.userAgent);
console.log('--------sys-platform：', uni.getSystemInfoSync().platform);

const appUpdate = () => {
  // https://ext.dcloud.net.cn/plugin?id=7286
  // https://apostarco.com/proxy-api/app-download/android/apostarco_14300/apostarco_14300_1.0.12.267_267.apk
  //#ifdef APP-PLUS
  // wgt必须是静默更新，如果是wgt更新，且必须先安装apk包打开app，然后再在后台创建wgt更新
  const sys = uni.getSystemInfoSync();
  handleChannelRes = handleChannel();

  var main = plus.android.runtimeMainActivity();
  var pkName = main.getPackageName();

  if (plus.runtime.channel === 'googleplay') return;
  plus.runtime.getProperty(plus.runtime.appid, (inf) => {
    // 'suerte777_11100' || 'gana100_11000'
    const channelId = handleChannelRes.channel;
    let apiParams = {
      appId: plus.runtime.appid,
      channelId,
      appType: sys.platform === 'ios' ? 2 : 1,
      innerVersion: inf.versionCode, // 打包时manifest设置的版本号
    };
    console.error('app更新---------params1', apiParams);
    console.error('app更新---------params2', inf);
    console.error('app更新---------params3', Number(inf.versionCode));

    COMMONAPI.getAppVersion(apiParams).then((versionRes) => {
      console.error('app更新---------versionRes', versionRes);
      //跳转更新页面 （注意！！！如果pages.json第一页的代码里有一打开就跳转其他页面的操作，下面这行代码最好写在setTimeout里面设置延时3到5秒再执行）
      let updateData = {
        // 版本更新内容 支持<br>自动换行
        describe: versionRes.versionDesc.replace(/\n/g, '<br>'),
        edition_url: versionRes.url, //apk、wgt包下载地址或者应用市场地址  安卓应用市场 market://details?id=xxxx 苹果store itms-apps://itunes.apple.com/cn/app/xxxxxx
        edition_force: versionRes.isForceUpdate ? 1 : 0, //是否强制更新 0代表否 1代表是
        package_type: versionRes.packageType || 0, //0是整包升级（apk或者appstore或者安卓应用市场） 1是wgt升级
        edition_issue: versionRes.editionIssue || 1, //是否发行  0否 1是 为了控制上架应用市场审核时不能弹出热更新框
        edition_number: versionRes.innerVersion, //版本号 最重要的manifest里的版本号 （检查更新主要以服务器返回的edition_number版本号是否大于当前app的版本号来实现是否更新）
        edition_name: versionRes.appVersion, // 版本名称 manifest里的版本名称
        edition_silence: versionRes.backstage === 0 ? 1 : 0, // 是否静默更新 0代表否 1代表是
      };
      setAppUpdateType(updateData.package_type);

      console.error('app更新---------sys', sys.platform, updateData);

      if (sys.platform === 'ios') {
      } else {
        // 1、网站下载：最新apk
        // 2、如果已经用过的，
        // 3、如果一打开apk就有高版本的wgt版本更新，则更新高版本的apk，因本地没有isAppUpdate
        let downloadurl = `${versionRes.url}${channelId}_${updateData.edition_name}_${updateData.edition_number}`;
        if (updateData.package_type === 1) {
          // wgt
          updateData.edition_url = `${downloadurl}.wgt`;
        } else {
          updateData.edition_url = `${downloadurl}.apk`;
        }
      }
      // 本地安装2.0 apk 需要更新为3.1wgt 则需要先安装3.0 apk
      try {
        $ganaStat.sendEvent(`app更新-${downloadurl}`, {
          apiParams: apiParams,
          getAppVersionRes: versionRes,
          updateData: updateData,
        });
      } catch (error) {}
      console.log(
        '================my-app更新-onLaunch:',
        pkName,
        Number(updateData.edition_number), // 后台返回版本号
        Number(inf.versionCode), // 打包时manifest设置的版本号
        updateData.edition_issue, // 是否发行
        updateData.package_type, // 0是整包升级（apk或者appstore或者安卓应用市场） 1是wgt升级
        updateData.edition_silence, // 是否静默更新
        updateData.edition_url, // 下载地址
        sys
      );
      // 判断后台返回版本号是否大于当前应用版本号
      if (Number(updateData.edition_number) > Number(inf.versionCode) && updateData.edition_issue === 1) {
        if (updateData.package_type == 1 && updateData.edition_silence == 1) {
          //调用静默更新方法 传入下载地址
          silenceUpdate(updateData.edition_url, `${proxy.$t('update.title')}`, `${proxy.$t('update.desc')}`);
        } else {
          //跳转更新页面 （注意！！！如果pages.json第一页的代码里有一打开就跳转其他页面的操作，下面这行代码最好写在setTimeout里面设置延时3到5秒再执行）
          uni.navigateTo({
            url: '/uni_modules/rt-uni-update/components/rt-uni-update/rt-uni-update?obj=' + JSON.stringify(updateData),
          });
        }
      } else {
      }
    });
  });
  // #endif
};

function loadChat() {
  setTimeout(() => {
    // #ifdef H5
    const { Crisp: CrispObj, initChat } = useChat();
    Crisp = CrispObj;
    initChat();
    // #endif
    // #ifdef APP-PLUS
    // global.ChatModule = uni.requireNativePlugin("ChatModule");
    // #endif
  }, 300);
}

async function reportInstall() {
  let deviceInfo = uni.getDeviceInfo();
  let installLog = {
    deviceId: deviceInfo.deviceId,
    deviceType: deviceInfo.deviceType,
    deviceModel: deviceInfo.deviceModel,
    devicePlatform: deviceInfo.platform,
    channel: getLocalData('s', ''),
    websiteCode: getLocalData('siteCode', ''),
    adChannel: getLocalData('channel', ''),
    tenant: getLocalData('nationCode', ''),
  };
  COMMONAPI.reportInstallActive(installLog).then((res) => {
    if (res) {
      setLocalData('install_active', res);
    } else {
      console.log('------->> reportInstall error:', res);
    }
  });
}

/**
 * 查询邀请代理账户，如果人数或奖励增长，展示动画；动画标识和变动标识本地存储，防止 Tabbar 重新初始化不展示
 * 为及时能展示金币动画，多个地方更新邀请账户，如下：
 * 1）onShow：实时性，展示动画
 * 2）登录后：获取账户信息，初始化原始值
 * 3）NavBar：点击余额数字时，要展示邀请账户奖励
 * 4）Tabbar：不主动请求，被动展示金币动画
 * 5）邀请页面：展示邀请代理账户信息
 * 6）pushMsg：展示金币动画
 * 7）需要更新账户：邀请账户一起更新，展示金币动画
 * 8）游戏退出：可忽略，页面切换必然会执行 onShow（待验证）
 */
function getInviteAccount() {
  USERAPI.queryUserInviteAccount()
    .then((res) => {
      if (checkInviteAccountChanged(res)) {
        setShowCoinsAnim(true);
        setInviteAccountChanged(true);
      }
      setInviteAccount(res);
    })
    .catch((err) => {
      console.error('onLaunch query invite account err: ', err);
    });
}

// app切换后台再打开不会执行onLaunch
onLaunch(async (params) => {
  const globalApp = getApp();
  const deviceInfo = uni.getDeviceInfo();
  /**
   * adTopVisibleStore
   * 0 默认
   * 1 显示
   * 2  不显示
   */
  setLocalData('adTopVisibleStore', '1'); // 处理顶部banner只显示1次
  // #ifdef APP-PLUS
  plus.screen.lockOrientation('portrait-primary'); // 禁止app横屏
  // #endif
  //执行后续流程 无网络再次连接不会走这里
  // console.log('onLaunch-----appLaunchFun')
  // await appLaunchFun(proxy);
  nextTick(() => {
    globalApp.globalData.$ganaStat = $ganaStat;
  });
  // 加载聊天模块
  loadChat();
  // 路由监听
  routingIntercept();

  if (platform == 'TMA') {
    let webApp = window.Telegram?.WebApp;
    webApp.ready();
    webApp.disableVerticalSwipes();
    if (!webApp.isExpanded) {
      webApp.expand();
    }
    webApp.enableClosingConfirmation();
  }

  // TODO: tg网站需要添加
  if (checkSite(['tg', 'tonace', 'tonwin365'], false) && platform != 'TMA') {
    gotoPage({
      page: 'noAccess',
      redirectTo: true, // reLaunch
    });
  }
  console.log('---->> install active event:', installActive);
});
// app点击上传图片也会走这里
// 登录后不走这里
onShow(async (params) => {
  // TODO: 优化appLaunchFun
  store.setCheckSiteConfig(false);
  if (!store.checkSiteConfig) {
    await appLaunchFun(proxy);
    // #ifdef APP-PLUS
    appUpdate();
    // #endif
    // TODO: 通过jekens脚本执行替换首页
    // uni.reLaunch({
    //   url: categoryData.value?.tabbarExpand?.tabPath,
    //   success() {
    //     // #ifdef APP-PLUS
    //     plus.navigator.closeSplashscreen()
    //     // #endif
    //   }
    // })

    handleChannelRes = handleChannel();
    // #ifdef H5
    const client = checkBrowser();
    // alert(JSON.stringify(client));
    console.log('---ua', navigator?.userAgent);
    if (client.isDesktop && platform !== 'TMA') {
      console.log('www---location.pathname', location.pathname);
      // 跳转iframe pc，会导致pc页面无法携带s、acc等参数
      if (
        location.pathname !== '/pages/main' &&
        location.pathname !== '/pages/h5fb' &&
        location.pathname !== '/static/web/pwaload' &&
        location.pathname !== '/static/web/pwaload/'
      ) {
        // TODO: ---3pwa调试
        let path = `${config._homePath}static/html/pc.html`;
        if (config._hostName) {
          path = `https://${config._hostName}/static/html/pc.html`;
        }
        return (location.href = path);
      }
    }
    // 添加facebook渠道统计代码，注册的时候需要
    const s = getParamsByStr(location.search, 's');
    s && setLocalData('s', s);
    const c = getParamsByStr(location.search, 'c');
    c && setLocalData('c', c);
    const g = getParamsByStr(location.search, 'g');
    g && setLocalData('g', g);
    const ad = getParamsByStr(location.search, 'ad');
    ad && setLocalData('ad', ad);

    const acc = getParamsByStr(location.search, 'acc') || getParamsByStr(location.search, 'aac');
    acc && setLocalData('acc', acc);

    const pixel = getParamsByStr(location.search, 'pixel') || getParamsByStr(location.search, 'pix');
    pixel && setLocalData('pixel', pixel);

    const siteType = getParamsByStr(location.search, 'siteType');
    siteType && setLocalData('siteType', siteType); // w2a

    const channel = getParamsByStr(location.search, 'channel');
    channel && setLocalData('channel', channel); // w2a

    const bbg = getParamsByStr(location.search, 'bbg');
    bbg && setLocalData('bbg', bbg);
    const ttclid = getParamsByStr(location.search, 'ttclid');
    ttclid && setLocalData('ttclid', ttclid);

    // 推特等分享链接
    const inviteUrl = getParamsByStr(location.search, 'inviteUrl');
    if (inviteUrl) {
      setLocalData('inviteUrl', inviteUrl);
      setLocalData('s', 6); // 区分邀请好友来源
    }
    // w2a 套壳的与 StRmg 渠道（s=13）要上报安装事件
    if (!installActive && siteType === 'w2a' && s == 13 && channel) {
      reportInstall();
    }
    // #endif

    // COMMONAPI.getAppVersion({
    //   appId: '__UNI__DDB6540',
    //   channelId: 'gana100_11000',
    //   appType: 1,
    //   innerVersion: 0  // 打包时manifest设置的版本号
    // })

    // 根据配置切换 tabbar
    if (!store.tabFirstExpanded && store.categoryData.tabbarExpand) {
      let item = store.categoryData.tabbarExpand;
      setSelectTabbar(item);
      if (item.tabPath) {
        gotoPage({
          page: `${item.tabPath}`,
        });
        setTabFirstExpanded(true);
      }
    }
    setCheckSiteConfig(true);
  } else {
    console.log('------>> all config loaded');
  }
});

// 监听基础参数请求回来之后
watch(
  () => store.checkSiteConfig,
  (current) => {
    if (current) {
      if (!isLogin.value && platform == 'TMA') {
        loginTMA();
      }
    }
  }
);

// h5登录后只会走这里
watch(
  () => store.isLogin,
  (current) => {
    console.log('---watch-isLogin', current);
    if (current) {
      switchSocket(true);
      setTimeout(() => {
        USERAPI.GetUserInfo().then(async (res) => {
          setBaseUserInfo(res);
          // 记录注册时间
          setLocalData(`${baseUserInfo.value.userName}-regTime`, Date.parse(res.createTime));
        });
        console.log('+++--watch-isLogin-');
        USERAPI.GetUserAccountInfo().then((res) => {
          setUserAccount(res);
          console.log('---请求账户信息：', baseUserInfo.value);
        });

        getInviteAccount();

        // 更新挂机金币
        if (platform == 'TMA') {
          USERAPI.getCoinAccount().then((res) => {
            console.log('---set-coins1:', res.balance);
            setUserCoins(res.balance);
          });
          USERAPI.getHookCoin().then((res) => {
            // 挂机弹窗金币
            if (res.hookBalance > 0) {
              setHookCoinsData(res);
              setIsShowHookCoinsDia(true);
            }
            setExchange({ exchangeName: res.exchangeName, exchangeLogo: res.exchangeLogo });
          });

          // 是否充过值
          BUYAPI.CheckUserIsRecharge().then((res) => {
            if (!res.userRecharge) {
              setIsShowRecharge1Dia(true);
            } else {
              setIsRecharged();
            }
          });
        } else {
          // 是否充过值
          BUYAPI.CheckUserIsRecharge().then((res) => {
            if (res.userRecharge) {
              setIsRecharged();
            }
          });
        }

        if (getLocalData('country') == 'NG') {
          USERAPI.getCoinAccount().then((res) => {
            console.log('---set-coins1:', res.balance);
            setUserCoins(res.balance);
          });
        }
      }, 1000);

      // // TODO: 测试环境使用
      // setTimeout(() => {
      //   // debugger;
      //   if (location.host == 'localhost:3000') {
      //     let data = JSON.parse(
      //       '{"registerTime":"2024-12-20T07:19:28.527+00:00","expiredTime":1734076168527,"max":90.0,"min":70.0,"show":"100"}'
      //     );
      //     data.expiredTime = Date.now() + 60 * 60 * 1000;
      //     setNewUserRouletteDia(true, data);
      //   }
      // }, 10000);
    } else {
      switchSocket(false);
      if (platform == 'TMA' && checkSiteConfig.value) {
        loginTMA();
      }
    }
  },
  { immediate: true } // 去掉会导致首次安装 跑马灯 无数据
);

// 已读未读消息显示消息红点
// watch(
//   () => store.baseUserInfo.stationUnReadCount,
//   () => {
//     if (store.baseUserInfo.stationUnReadCount > 0) {
//       uni.showTabBarRedDot({ index: 3 });
//     } else {
//       uni.hideTabBarRedDot({ index: 3 });
//     }
//   }
// );

// 监控是否需要获取用户信息（有充值到账的站内信息）
watch(
  () => store.isNeedGetUserInfo,
  (current) => {
    if (current) {
      setIsNeedGetUserInfo(false);
      console.log('+++--watch--updateUserInfo');
      USERAPI.GetUserAccountInfo().then((res) => {
        setUserAccount(res);
        // console.log('---更新账户信息：', baseUserInfo.value);
      });

      getInviteAccount();

      if (platform == 'TMA' || getLocalData('country') == 'NG') {
        USERAPI.getCoinAccount().then((res) => {
          console.log('---set-coins:', res.balance);
          setUserCoins(res.balance);
        });
      }
    }
  }
);

watch(
  () => store.receiveInvitePushMsg,
  (receivedNewMsg) => {
    if (receivedNewMsg) {
      console.log('-----receiveInvitePushMsg---account44');
      getInviteAccount();
      store.setReceiveInvitePushMsg(false);
    }
  }
);

function closeSocket() {
  socketManager.closeSocket();
}

// tg获取登录信息
function loginTMA() {
  showLoading();
  let webApp = window.Telegram?.WebApp;
  console.log('---tg-webApp: ', webApp);

  let paramsObj = {
    password: '',
    source: 15,
    clientType: 6,
    thirdRegInfo: webApp?.initData,
  };

  let startParam = webApp?.initDataUnsafe?.start_param;
  console.log('---start_param: ', startParam);
  if (startParam?.startsWith('c_')) {
    paramsObj.dataReportJson = { c: startParam.split('_')[1] };
  } else {
    paramsObj.proxyCode = startParam;
  }

  if (webApp?.initDataUnsafe) {
    paramsObj.password = md5(`T${webApp?.initDataUnsafe?.user?.id}`); // md5
    // console.log('---paramsObj', paramsObj);

    USERAPI.registerTgUser(paramsObj)
      .then((res) => {
        hideLoading();
        console.log('---tg注册/登录成功', res);
        setToken(res.access_token);
        switchLogin(true, res.user_name);
        // setBotToken(res.bot_token);
        initWallet();
      })
      .catch((err) => {
        toast(err?.msg || err);
      });
  }
}

async function initWallet() {
  console.log('---开始initWallet');
  // let manifestUrl = 'https://tg.igming.com/static/tonconnect-manifest.json';
  let manifestUrl = `${config._homePath}/static/tonconnect-manifest.json`;

  let tonConnectUI = new TonConnectUI({
    manifestUrl,
  });
  global.tonConnectUI = tonConnectUI;
  console.log('--++tonConnectUI：', global.tonConnectUI);
  console.log('--++tonConnectUI?.connector：', tonConnectUI?.connector);
  console.log('--++tonConnectUI?.walletInfoStorage?.localStorage：', tonConnectUI?.walletInfoStorage?.localStorage);
  console.log(
    '--++tonConnectUI?.connector?.bridgeConnectionStorage：',
    tonConnectUI?.connector?.bridgeConnectionStorage
  );

  console.log('--++current Wallet：', baseUserInfo.value.wallet);
  const unsubscribe = tonConnectUI.onStatusChange(async (wallet) => {
    console.log('--++change wallet：', wallet);
    if (wallet) {
      try {
        let walletStr = await getFriendlyCode(wallet?.account?.address);
        console.log('--++change walletCode：', walletStr);
        setWallet(walletStr);
      } catch (err) {}
    }
  });
}

onUnmounted(() => {
  closeSocket();
});

onHide(() => {
  // closeSocket();
});

async function switchSocket(userFlag) {
  console.log('socketManager------', socketManager);
  if (store.checkSiteConfigRes?.siteCode || getLocalData('siteCode')) {
    socketManager.switchSocket(userFlag);
    // if (userFlag) {
    //   if (socketMarquee) {
    //     socketMarquee.close();
    //     socketMarquee = null;
    //   }
    //   if (!socketUser) {
    //     socketUser = useUserSocket();
    //   }
    // } else {
    //   if (socketUser) {
    //     socketUser.close();
    //     socketUser = null;
    //   }
    //   if (!socketMarquee) {
    //     socketMarquee = useMarqueeSocket();
    //   }
    // }
  } else {
    // console.log('switchSocket---appLaunchFun')
    await appLaunchFun(proxy);
    switchSocket(userFlag);
  }
}

//- 全局监控体育弹窗的状态 //- sport页面状态管理
const isShowSportsDialog = ref(false);
provide('isShowSportsDialog', isShowSportsDialog);
provide('setIsShowSportsDialog', (val) => {
  isShowSportsDialog.value = val;
});
</script>

<style lang="scss">
/*每个页面公共css */
@import '@/uni_modules/uview-plus/index.scss';
</style>
